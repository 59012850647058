import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

document.addEventListener('DOMContentLoaded', function() {
    document.getElementById('loginButton').addEventListener('click', login);

    const locationFilter = document.getElementById('locationFilter');
    locationFilter.addEventListener('click', function(event) {
        if (event.target.name === 'locationFilter') {
            const selectedLocation = event.target.value;
            const data = JSON.parse(localStorage.getItem('scheduleData'));
            updateCalendar(data, selectedLocation);
        }
    });
});

let calendar;

function resetModal() {
    document.getElementById('eventForm').reset();
    document.getElementById('weekdayInputs').innerHTML = '';
    document.getElementById('entryType').value = 'single'; // Set default value
    const radioButtons = document.querySelectorAll('input[name="location"]');
    radioButtons.forEach(radio => {
        radio.checked = false; // Uncheck all radio buttons
    });
}



function getFilteredEventsFromData(data, locationFilter) {
    const events = [];
    for (const year in data) {
        for (const location in data[year]["Standorte"]) {
            if (locationFilter === "all" || location === locationFilter) {
                data[year]["Standorte"][location].forEach(event => {
                    events.push({
                        title: event.Lektion || 'Feiertag / Frei',
                        start: event.Datum,
                        location: location
                    });
                });
            }
        }
    }
    return events;
}

function updateCalendar(data, locationFilter = 'all') {
    const calendarEl = document.getElementById('calendar');
    const filteredEvents = getFilteredEventsFromData(data, locationFilter);

    if (calendar) {
        // Aktualisieren Sie die Ereignisse, ohne den Kalender neu zu rendern
        calendar.removeAllEventSources();
        calendar.addEventSource(filteredEvents);
    } else {
        // Initialisieren Sie den Kalender nur einmal
        calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin],
            initialView: 'dayGridMonth',
            locale: 'de',
            firstDay: 1,
            dateClick: function(info) {
                console.log('Date clicked:', info.dateStr); // Debugging-Ausgabe
                openModal(info.dateStr);
            },
            events: filteredEvents,
            eventClick: function(info) {
                console.log('Event clicked:', info.event);
                openEditModal(info.event);
            },
            dayCellDidMount: function(info) {
                const button = document.createElement('button');
                button.innerHTML = '+';
                button.className = 'add-event-btn';
                button.onclick = function () {
                    console.log('Button clicked for date:', info.date); // Debugging-Ausgabe
                    openModal(info.date.toISOString());
                };
                info.el.querySelector('.fc-daygrid-day-top').appendChild(button);
            }
        });
        calendar.render();
    }
}



function login() {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    fetch('/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(data => {
        if (data.status === 'success') {
            document.getElementById('loginArea').style.display = 'none';
            document.getElementById('dataArea').style.display = 'block';
            loadData();
        } else {
            alert('Falscher Benutzername oder Passwort!');
        }
    })
    .catch(error => {
        console.error('Error during login:', error);
        alert('Login fehlgeschlagen: ' + error.message);
    });
}



function loadData() {
    fetch('/data')
        .then(response => response.json())
        .then(data => {
            if (!data) {
                console.log("Keine Daten erhalten");
                return;
            }
            localStorage.setItem('scheduleData', JSON.stringify(data));
            updateCalendar(data, 'all');  // Initialisiere den Kalender mit allen Standorten
            populateLocations(data);
        })
        .catch(error => {
            console.error('Error loading data:', error);
            alert('Daten konnten nicht geladen werden: ' + error.message);
        });
}



function getEventsFromData(data) {
    const events = [];
    for (const year in data) {
        for (const location in data[year]["Standorte"]) {
            data[year]["Standorte"][location].forEach(event => {
                events.push({
                    title: event.Lektion || 'Feiertag / Frei',
                    start: event.Datum,
                    location: location
                });
            });
        }
    }
    return events;
}

function populateLocations(data) {
    const locationSelect = document.getElementById('modalLocation');
    locationSelect.innerHTML = '';
    for (const year in data) {
        for (const location in data[year]["Standorte"]) {
            const radioInput = document.createElement('input');
            radioInput.type = 'radio';
            radioInput.id = location;
            radioInput.name = 'location';
            radioInput.value = location;

            const label = document.createElement('label');
            label.htmlFor = location;
            label.textContent = location;

            locationSelect.appendChild(radioInput);
            locationSelect.appendChild(label);
            locationSelect.appendChild(document.createElement('br'));
        }
    }
}

function openModal(dateStr) {
    resetModal();  // Reset modal fields before opening

    const date = new Date(dateStr);
    const localDateStr = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    console.log('Opening modal for date:', localDateStr, 'Day of week:', date.getDay());

    const modal = document.getElementById("eventModal");
    const closeBtn = document.getElementsByClassName("close")[0];
    const modalLessonType = document.getElementById('modalLessonType');
    const modalDate = document.getElementById('modalDate');
    const entryTypeSelect = document.getElementById('entryType');

    modalDate.value = localDateStr;
    modalLessonType.value = 'lesson'; // Set default lesson type

    closeBtn.onclick = function() {
        modal.style.display = "none";
    }

    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    }

    document.getElementById('deleteButton').style.display = 'none';

    const radioButtons = document.querySelectorAll('input[name="location"]');
    radioButtons.forEach(radio => {
        radio.onclick = () => autoSelectLessons(radio.value, localDateStr);
    });

    entryTypeSelect.onchange = function() {
        if (entryTypeSelect.value === 'recurring') {
            generateWeekdayInputs(localDateStr);
        } else {
            generateSingleEventInput(localDateStr);
        }
    }

    generateSingleEventInput(localDateStr); // Default to single event input

    modal.style.display = "flex";
}


function generateWeekdayInputs(startDateStr) {
    const startDate = new Date(startDateStr);
    const localStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const dayOfWeek = localStartDate.getDay();
    console.log('Generating inputs for start date:', localStartDate, 'Day of week:', dayOfWeek);
    const weekdayInputsDiv = document.getElementById('weekdayInputs');
    weekdayInputsDiv.innerHTML = '';

    if (dayOfWeek === 0 || dayOfWeek === 6) {
        alert('Bitte einen Wochentag (Montag bis Freitag) auswählen.');
        return;
    }

    const lessons = [
        "Theoriestunde 1", "Theoriestunde 2", "Theoriestunde 3", "Theoriestunde 4",
        "Theoriestunde 5", "Theoriestunde 6", "Theoriestunde 7", "Theoriestunde 8",
        "Theoriestunde 9", "Theoriestunde 10", "Theoriestunde 11", "Theoriestunde 12",
        "Zusatz 13", "Zusatz 14", "Motorrad 1", "Motorrad 2", "Motorrad 3", "Motorrad 4",
        "Aufbauseminar für Fahranfänger", ""
    ];

    let lessonIndex = 0; // Index für die Lektionen

    for (let i = dayOfWeek; i <= 5; i++) {
        const currentDay = new Date(localStartDate);
        currentDay.setDate(localStartDate.getDate() + (i - dayOfWeek));

        const dayLabel = document.createElement('label');
        dayLabel.textContent = currentDay.toLocaleDateString('de-DE', { weekday: 'long', day: 'numeric', month: 'long' });

        const lessonSelect = document.createElement('select');
        lessonSelect.classList.add('weekdayLesson');
        lessonSelect.dataset.date = currentDay.toISOString().split('T')[0];

        populateLessonOptions(lessonSelect);
        if (lessonIndex < lessons.length) {
            lessonSelect.value = lessons[lessonIndex]; // Setze den Wert des Dropdowns
            lessonIndex++;
        }

        weekdayInputsDiv.appendChild(dayLabel);
        weekdayInputsDiv.appendChild(lessonSelect);
        weekdayInputsDiv.appendChild(document.createElement('br'));
    }
}



function populateLessonOptions(selectElement, selectedValue = '') {
    const lessons = [
        "Theoriestunde 1", "Theoriestunde 2", "Theoriestunde 3", "Theoriestunde 4",
        "Theoriestunde 5", "Theoriestunde 6", "Theoriestunde 7", "Theoriestunde 8",
        "Theoriestunde 9", "Theoriestunde 10", "Theoriestunde 11", "Theoriestunde 12",
        "Zusatz 13", "Zusatz 14", "Motorrad 1", "Motorrad 2", "Motorrad 3", "Motorrad 4",
        "Aufbauseminar für Fahranfänger", ""
    ];
    selectElement.innerHTML = ''; // Clear previous options
    lessons.forEach(lesson => {
        const option = document.createElement('option');
        option.value = lesson;
        option.textContent = lesson === "" ? "Frei/Feiertag" : lesson; // Set text for empty value
        selectElement.appendChild(option);
    });

    if (selectedValue) {
        selectElement.value = selectedValue;
    }
}

function autoSelectLessons(location, dateStr) {
    const data = JSON.parse(localStorage.getItem('scheduleData'));
    const year = new Date(dateStr).getFullYear();
    if (!data[year] || !data[year]["Standorte"][location]) return;

    const lessons = data[year]["Standorte"][location].filter(event => event.Lektion && new Date(event.Datum) < new Date(dateStr));
    lessons.sort((a, b) => new Date(b.Datum) - new Date(a.Datum));

    if (lessons.length > 0) {
        let lastLessonNumber = parseInt(lessons[0].Lektion.match(/\d+/)[0]);
        const weekdayLessonSelects = document.querySelectorAll('.weekdayLesson');

        weekdayLessonSelects.forEach((select, index) => {
            const nextLesson = lessons[0].Lektion.replace(/\d+/, lastLessonNumber + 1);
            const option = Array.from(select.options).find(opt => opt.value === nextLesson);

            if (option) {
                select.value = option.value;
                lastLessonNumber++;
            } else {
                select.value = ''; // Keine gültige nächste Lektion gefunden
            }
        });
    }
}

function openEditModal(event) {
    resetModal();  // Reset modal fields before opening

    console.log('Opening edit modal for event:', event); // Debugging-Ausgabe

    const modal = document.getElementById("eventModal");
    const closeBtn = document.getElementsByClassName("close")[0];
    const modalLessonType = document.getElementById('modalLessonType');
    const modalDate = document.getElementById('modalDate');
    const modalLocation = document.getElementsByName('location');
    const modalLesson = document.getElementById('modalLesson');

    modalDate.value = event.startStr;
    modalDate.dataset.originalDate = event.startStr;  // Store original date in a data attribute

    modalLessonType.value = 'lesson';

    if (modalLesson) {
        modalLesson.value = event.title;
    } else {
        console.error("modalLesson is null");
    }

    modalLocation.forEach(radio => {
        if (radio.value === event.extendedProps.location) {
            radio.checked = true;
        }
    });

    // Setze den aktuellen Wert des Eintrags im Dropdown
    const lessonSelect = document.querySelector('.weekdayLesson');
    if (lessonSelect) {
        populateLessonOptions(lessonSelect, event.title);
    }

    closeBtn.onclick = function() {
        modal.style.display = "none";
    }

    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    }

    document.getElementById('deleteButton').style.display = 'block'; // Show delete button for existing events
    document.getElementById('deleteButton').onclick = function() {
        deleteEvent(event);
    };

    // Nur den angeklickten Termin anzeigen
    generateSingleEventInput(event.startStr, event.title);

    modal.style.display = "flex";
}

function generateSingleEventInput(dateStr, selectedLesson = '') {
    const startDate = new Date(dateStr);
    const localStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const weekdayInputsDiv = document.getElementById('weekdayInputs');
    weekdayInputsDiv.innerHTML = '';

    const dayLabel = document.createElement('label');
    dayLabel.textContent = localStartDate.toLocaleDateString('de-DE', { weekday: 'long', day: 'numeric', month: 'long' });

    const lessonSelect = document.createElement('select');
    lessonSelect.classList.add('weekdayLesson');
    lessonSelect.dataset.date = localStartDate.toISOString().split('T')[0];
    populateLessonOptions(lessonSelect, selectedLesson); // Pass the selectedLesson

    weekdayInputsDiv.appendChild(dayLabel);
    weekdayInputsDiv.appendChild(lessonSelect);
    weekdayInputsDiv.appendChild(document.createElement('br'));
}



function deleteEvent(event) {
    const data = JSON.parse(localStorage.getItem('scheduleData')) || {};
    const year = event.start.getFullYear();
    const location = event.extendedProps.location;

    if (data[year] && data[year]["Standorte"][location]) {
        data[year]["Standorte"][location] = data[year]["Standorte"][location].filter(e => e.Datum !== event.startStr);
        localStorage.setItem('scheduleData', JSON.stringify(data));

        fetch('/deleteevent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                year: year,
                location: location,
                date: event.startStr
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === "Event deleted successfully") {
                localStorage.setItem('scheduleData', JSON.stringify(data.data));
                updateCalendar(data.data);  // Aktualisiere den Kalender
                document.getElementById("eventModal").style.display = "none";  // Schließen Sie das Modal
            } else {
                throw new Error('Fehler beim Löschen des Ereignisses');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Fehler beim Löschen des Ereignisses: ' + error.message);
        });
    }
}


document.getElementById('eventForm').addEventListener('submit', function(e) {
    e.preventDefault();
    const dateStr = document.getElementById('modalDate').value;
    const originalDateStr = document.getElementById('modalDate').dataset.originalDate || dateStr;
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const locationElement = document.querySelector('input[name="location"]:checked');
    const entryType = document.getElementById('entryType').value;

    if (!locationElement) {
        alert('Bitte Standort auswählen!');
        return;
    }

    const location = locationElement.value;
    const lessonType = document.getElementById('modalLessonType').value;

    if (entryType === 'single') {
        const event = {
            Datum: dateStr,
            Lektion: document.querySelector('.weekdayLesson').value,
            Wochentag: date.toLocaleDateString('de-DE', { weekday: 'long' })
        };
        addEventToData(year, location, event, originalDateStr);
    } else {
        const events = Array.from(document.querySelectorAll('.weekdayLesson')).map(select => {
            const eventDate = new Date(select.dataset.date);
            const localEventDateStr = new Date(eventDate.getTime() - eventDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            return {
                Datum: localEventDateStr,
                Lektion: select.value,
                Wochentag: eventDate.toLocaleDateString('de-DE', { weekday: 'long' })
            };
        });
        fetch('/addmultievents', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                year: year,
                location: location,
                events: events
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === "Events added/updated successfully") {
                localStorage.setItem('scheduleData', JSON.stringify(data.data));
                updateCalendar(data.data);
            } else {
                throw new Error('Fehler beim Hinzufügen/Aktualisieren der Ereignisse');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Fehler beim Hinzufügen/Aktualisieren der Ereignisse: ' + error.message);
        });
    }

    document.getElementById("eventModal").style.display = "none";
});




function addEventToData(year, location, event, originalDate) {
    const data = JSON.parse(localStorage.getItem('scheduleData')) || {};
    if (!data[year]) data[year] = { "Standorte": {} };
    if (!data[year]["Standorte"][location]) data[year]["Standorte"][location] = [];

    const existingEventIndex = data[year]["Standorte"][location].findIndex(e => e.Datum === originalDate);
    if (existingEventIndex !== -1) {
        // Update existing event
        data[year]["Standorte"][location][existingEventIndex] = event;
    } else {
        // Add new event
        data[year]["Standorte"][location].push(event);
    }

    localStorage.setItem('scheduleData', JSON.stringify(data));

    fetch('/addevent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            year: year,
            location: location,
            event: {
                Datum: event.Datum,
                Wochentag: event.Wochentag,
                Lektion: event.Lektion
            },
            originalDate: originalDate
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data.status === "Event added/updated successfully") {
            localStorage.setItem('scheduleData', JSON.stringify(data.data));
            updateCalendar(data.data);  // Aktualisiere den Kalender
            document.getElementById("eventModal").style.display = "none";  // Schließen Sie das Modal
        } else {
            throw new Error('Fehler beim Hinzufügen/Aktualisieren des Ereignisses');
        }
    })
    .catch(error => {
        console.error('Error:', error);
        alert('Fehler beim Hinzufügen/Aktualisieren des Ereignisses: ' + error.message);
    });
}



function initializeEventListeners() {
    const saveDataButton = document.getElementById('saveData');
    if (saveDataButton) {
        saveDataButton.addEventListener('click', saveData);
    }

    document.getElementById('modalLessonType').addEventListener('change', function() {
        const lessonDiv = document.getElementById('modalLessonDiv');
        if (this.value === 'lesson') {
            lessonDiv.style.display = 'block';
        } else {
            lessonDiv.style.display = 'none';
        }
    });
}

function saveData() {
    const data = JSON.parse(localStorage.getItem('scheduleData'));
    fetch('/data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        if (response.ok) {

        } else {
            throw new Error('Fehler beim Speichern der Daten');
        }
    })
    .catch(error => alert('Fehler beim Speichern der Daten: ' + error.message));
}
